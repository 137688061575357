import React from 'react'
import styled from 'styled-components'
import ITheme from 'theme/ITheme'
import Box from './Box'
import Grid from './Grid'

const SkeletonContainer = styled.div`
  background-color: ${props => (props.theme as ITheme).colors.common.white};
  box-shadow: 1px 1px 8px 0 rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 16px;
`

const SkeletonCover = styled.div`
  height: 140px;
  background-color: ${props => (props.theme as ITheme).colors.light2};
  padding: 10px;
`

const SkeletonPanel = styled.div`
  padding: 10px;
`

const SkeletonText = styled.div<{width: string; height?: string}>`
  width: ${props => props.width};
  height: ${props => props.height || '16px'};
  background-color: ${props => (props.theme as ITheme).colors.light2};
  margin-bottom: 8px;
  border-radius: 4px;
`

const CardListSkeleton: React.FC = () => {
  return (
    <Grid columns={3} gap={3}>
      {[...Array(6)].map((_, index) => (
        <SkeletonContainer key={index}>
          <SkeletonCover>
            <Box justify="space-between" width="100%" margin={{bottom: 2}}>
              <SkeletonText width="30%" />
            </Box>
            <Box justify="space-between" width="100%">
              <div>
                <SkeletonText width="60%" />
                <SkeletonText width="40%" />
              </div>
              <SkeletonText width="20%" height="24px" />
            </Box>
          </SkeletonCover>
          <SkeletonPanel>
            <Box justify="space-between" align="center" margin={{bottom: 2}}>
              <SkeletonText width="30%" />
              <SkeletonText width="20%" />
            </Box>
            {[...Array(2)].map((_, featureIndex) => (
              <Box key={featureIndex} justify="space-between" align="center" margin={{bottom: 1}}>
                <SkeletonText width="40%" />
                <SkeletonText width="20%" />
              </Box>
            ))}
          </SkeletonPanel>
        </SkeletonContainer>
      ))}
    </Grid>
  )
}

export default CardListSkeleton
