import {partyAtom} from 'atoms/party'
import {pageAtom, pageSizeAtom, subscriptionsAtom} from 'atoms/subscription'
import Button from 'components/Button'
import {ISubscriptionPlan} from 'domain/IParty'
import useLocalization from 'hooks/useLocalization'
import {useAtom, useAtomValue} from 'jotai'
import React from 'react'
import {useHistory} from 'react-router-dom'
import InnerScroll from '../../../../components/InnerScroll'
import Page from '../../../../components/Page'
import SubscriptionList, {ActionSection, Row} from './SubscriptionList'
import {PaginateWithSelection} from 'components/Paginate/Paginate'
import Box from 'components/Box'
import styled from 'styled-components'
import Grid from 'components/Grid'
import {Card} from '@mui/material'

const RESULT_PER_PAGE_OPTIONS = [5, 10, 20]

const SkeletonItem = styled.div<{width?: string; height?: string}>`
  background: #e0e0e0;
  height: ${props => props.height || '20px'};
  width: ${props => props.width || '100%'};
  margin-bottom: 8px;
  border-radius: 4px;
`

const SubscriptionPage: React.FC = () => {
  const {translate} = useLocalization()
  const history = useHistory()
  const {data: subscriptions, loading} = useAtomValue(subscriptionsAtom)
  const party = useAtomValue(partyAtom)
  const [page, setPage] = useAtom(pageAtom)
  const [pageSize, setPageSize] = useAtom(pageSizeAtom)
  const canCreateSubscription = party?.subscriptionPlan === ISubscriptionPlan.BASIC || subscriptions?.data?.length === 0

  return (
    <Page
      title={translate('Subscriptions')}
      description={translate('View your active and past subscriptions')}
      corner={
        canCreateSubscription && (
          <Button
            data-cy="create-subscription-button"
            margin={{right: 4}}
            onClick={() => {
              history.push('/consumer/company/subscription/add')
            }}
            small
          >
            {translate('Create subscription')}
          </Button>
        )
      }
    >
      <InnerScroll padded>
        {loading ? (
          <Grid columns={1} gap={1} data-cy="contract-list-items">
            {[...Array(3)].map((_, index) => (
              <Card key={index} style={{padding: '16px', marginBottom: '16px', height: '222px'}}>
                <Grid columns={3} gap={2}>
                  <div>
                    <SkeletonItem width="80%" height="32px" style={{marginBottom: '32px'}} />
                    <SkeletonItem width="60%" height="24px" style={{marginTop: '8px'}} />
                  </div>
                  <div>
                    <SkeletonItem width="50%" height="24px" style={{marginTop: '64px'}} />
                  </div>
                  <div>
                    <SkeletonItem width="40%" height="24px" style={{marginTop: '64px'}} />
                  </div>
                </Grid>
                <Box margin={{top: 2}} justify="flex-end" width="100%">
                  <SkeletonItem width="120px" height="36px" style={{marginTop: '32px'}} />
                </Box>
              </Card>
            ))}
          </Grid>
        ) : (
          <SubscriptionList subscriptions={subscriptions?.data} loading={loading} />
        )}
      </InnerScroll>
      <Box align="flex-end" justify="center">
        <PaginateWithSelection
          activePage={page}
          resultsPerPage={pageSize}
          resultsPerPageOptions={RESULT_PER_PAGE_OPTIONS}
          totalPages={subscriptions?.totalPages || 1}
          handlePageChange={value => setPage(value)}
          handleResultsPerPageChange={value => {
            setPageSize(value)
            setPage(1)
          }}
        />
      </Box>
    </Page>
  )
}

export default SubscriptionPage
