import React from 'react'
import useLocalization from '../../../../hooks/useLocalization'
import useTheme from '../../../../hooks/useTheme'
import {sumBy} from 'lodash-es'
import IParty from '../../../../domain/IParty'
import ResourceSummary from '../Summary/ResourceSummary'
import EnergySummaryItem from '../Summary/EnergySummaryItem'
import ResourceSummaryItem from '../Summary/ResourceSummaryItem'

interface IProps extends React.PropsWithChildren {
  parties: IParty[]
}

const CustomersSummary: React.FC<IProps> = ({parties}) => {
  const {translate} = useLocalization()
  const theme = useTheme()

  const totalConsumption = sumBy(parties, p => +(p.totalAvgYearlyConsumptionMwh || 0))
  const unitSuffix = `/${translate('year')}`

  return (
    <ResourceSummary>
      <ResourceSummaryItem
        title={translate('Total customers')}
        value={parties.length}
        color={theme.colors.light2}
        max={parties.length}
      />
      <EnergySummaryItem
        title={translate('Total yearly consumption')}
        value={totalConsumption}
        color={theme.colors.yellow2}
        max={totalConsumption}
        unitSuffix={unitSuffix}
      />
      <EnergySummaryItem
        title={translate('Average yearly consumption')}
        value={totalConsumption / parties.length}
        color={theme.colors.primary}
        max={totalConsumption / parties.length}
        unitSuffix={unitSuffix}
      />
    </ResourceSummary>
  )
}

export default CustomersSummary
