import {subscriptionIdAtom} from 'atoms/subscription'
import {EventSourcePolyfill} from 'event-source-polyfill'
import {useAtomValue, useSetAtom} from 'jotai'
import {useEffect, useState} from 'react'
import useResource from './useResource'
import {partyAtom} from 'atoms/party'
import {rootServiceAtom} from 'atoms/general'
import env from '@beam-australia/react-env'

const EventSource = EventSourcePolyfill

enum ServerSentEvents {
  SUBSCRIPTION_PLAN_UPGRADED = 'SUBSCRIPTION_PLAN_UPGRADED',
  SUBSCRIPTION_PLAN_DOWNGRADED = 'SUBSCRIPTION_PLAN_DOWNGRADED',
}

function useSSE() {
  const setSubscription = useSetAtom(subscriptionIdAtom)
  const {party} = useResource(store => ({
    party: store.profileStore.party,
  }))
  const setParty = useSetAtom(partyAtom)
  const [eventSource, setEventSource] = useState<EventSource | null>(null)
  const [authToken, setAuthToken] = useState(null)
  const rootService = useAtomValue(rootServiceAtom)

  useEffect(() => {
    const fetchToken = async () => {
      const token = await rootService?.authenticationService?.getToken()
      setAuthToken(token)
    }
    if (party) {
      fetchToken()
    }
  }, [party])

  useEffect(() => {
    return () => {
      if (eventSource) {
        eventSource.close()
      }
    }
  }, [eventSource])

  useEffect(() => {
    if (authToken) {
      const eventSourceInitDict = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
      const source = new EventSource(`${env('CORE_V2_URL')}/v2/api/events`, eventSourceInitDict)
      setEventSource(source)

      source.onmessage = event => {
        const jsonEvent = JSON.parse(event.data)
        if (jsonEvent?.kind === ServerSentEvents.SUBSCRIPTION_PLAN_UPGRADED) {
          if (jsonEvent?.payload?.partyId === party?.id) {
            setParty({
              ...party,
              subscriptionPlan: jsonEvent?.payload?.newPlan,
            })
          }
        }
        if (jsonEvent?.kind === ServerSentEvents.SUBSCRIPTION_PLAN_DOWNGRADED) {
          if (jsonEvent?.payload?.partyId === party?.id) {
            setParty({
              ...party,
              subscriptionPlan: jsonEvent?.payload?.newPlan,
            })
            setSubscription(jsonEvent?.payload?.subscriptionId)
          }
        }
      }
    }
  }, [authToken])

  return eventSource
}

export default useSSE
