import React from 'react'
import Aside from './Aside'
import Button, {ClearButton} from '../../../Button'
import useTheme from 'hooks/useTheme'
import FormSelect from 'components/Form/FormSelect'
import styled from 'styled-components'
import {IProps as ISelectProps} from 'components/Form/FormSelect'
import Link from 'components/Link'
import HorizStack from 'components/HorizStack'
import Box, {IProps as IBoxProps} from 'components/Box'
import loginImage from 'assets/images/login.webp'
import ITheme from 'theme/ITheme'
import Alerts from 'components/Layout/Alerts'
import LogoutButton from 'components/LogoutButton'
import useProfile from 'hooks/useProfile'

export const FormContainer = styled.div`
  font-size: 18px;
  line-height: 22px;
  display: flex;
  min-height: 100%;
  width: 100%;
`

export const CancelButton = styled(ClearButton)`
  background-color: transparent;
  width: 100px;
  border-radius: 160px;
  border: 1px solid rgb(0, 0, 0);
`

export const Container: React.FC<IBoxProps> = ({children, ...rest}) => {
  return (
    <HorizStack fillHeight={true} {...rest}>
      <FormContainer>
        <Box grow>{children}</Box>
      </FormContainer>
    </HorizStack>
  )
}

export interface IStyledSelProps extends ISelectProps {
  placeholder: string
}

export const StyledFormSelect: React.FC<IStyledSelProps> = ({options, placeholder, ...rest}) => {
  const theme = useTheme()

  return (
    <FormSelect
      {...rest}
      options={placeholder ? [{label: placeholder, value: undefined}].concat(options) : options}
      style={{
        backgroundColor: 'rgba(255, 255, 255, .5)',
        border: `1px solid ${theme.colors.secondary}`,
        borderRadius: 4,
      }}
    ></FormSelect>
  )
}

export const OnboardLink = styled(Link)`
  min-height: 48px;
  width: 130px;
  border-radius: 24px;
  padding: 0 20px;
  font-size: 20px;
  font-weight: 500;
  line-height: 48px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${props => (props.theme as ITheme).colors.common.black};

  &:hover {
    background-color: ${props => (props.theme as ITheme).colors.contrastBackground};
  }
`
const LayoutContainer = styled.div`
  background-color: ${props => (props.theme as ITheme).colors.globalBackground};
  background-image: url(${loginImage});
  background-position: 0%;
  background-size: cover;
  min-width: 1330px;
  height: 100vh;
`

const LeftContainer = styled(Box)`
  min-width: 60%;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 30px 0;
  display: flex;
  overflow: auto;
`

const RightContainer = styled(Box)`
  width: 100%;
  height: 100%;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  backdrop-filter: blur(12px);
  color: ${props => (props.theme as ITheme).colors.secondary};
  background-image: linear-gradient(rgba(120, 249, 111, 0.77), rgba(120, 249, 111, 0.77));
  padding: 48px;
`

const StyledLogoutButton = styled(LogoutButton)`
  color: ${props => (props.theme as ITheme).colors.common.black};

  &:before {
    filter: brightness(0);
  }
  &:hover {
    background-color: ${props => (props.theme as ITheme).colors.contrastBackground};
  }
`

const Logout = () => {
  const {user} = useProfile()

  return user ? (
    <Box style={{position: 'absolute', top: 0, right: 0}} pad={2}>
      <StyledLogoutButton />
    </Box>
  ) : null
}

interface IProps extends React.PropsWithChildren {
  showLogout?: boolean
}

// Old layout logic
const Layout: React.FC<IProps> = ({children}) => (
  <LayoutContainer>
    <Box direction="row" height="100%">
      <LeftContainer>
        <Aside />
      </LeftContainer>
      <RightContainer>{children}</RightContainer>
      <Logout />
    </Box>
    <Alerts />
  </LayoutContainer>
)

export default Layout
