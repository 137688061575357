import React, {useState, useRef} from 'react'
import {greenCertificatesAtom, greenCertsDateFilterAtom} from 'atoms/portfolioOverview'
import Box from 'components/Box'
import Filter, {IChoice} from 'components/Filter'
import Input from 'components/Input'
import Page from 'components/Page'
import {capitalize} from 'lodash-es'
import CertificatesChart from 'components/Platform/Portfolio/GreenCertificate/CertificatesChart'
import Select from 'components/Select'
import Text from 'components/Text'
import {SiteProductionTechnology} from 'domain/ISite'
import {getChangeEventValue} from 'helpers/misc'
import useLocalization from 'hooks/useLocalization'
import {useAtom, useAtomValue} from 'jotai'
import dayjs from 'dayjs'
import InnerScroll from 'components/InnerScroll'
import Button from 'components/Button'
import useTheme from 'hooks/useTheme'
import {getHumanReadableCountry} from 'helpers/location'
import MultiSelect from 'components/MultiSelect'
import {StyledHeadCell, StyledRow, StyledTable} from 'components/Table'
import Form from 'components/Form'
import FormSelect from 'components/Form/FormSelect'
import FormInput from 'components/Form/FormInput'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import {IconButton} from '@mui/material'
import {uniqueId} from 'lodash-es'
import {rootServiceAtom} from 'atoms/general'
import {partyAtom} from 'atoms/party'
import useStore from 'hooks/useStore'
import FullscreenLoader from 'components/FullscreenLoader'
import {getMonths, getPastYears} from 'helpers/date'
import Link from 'components/Link'

const generateRange = (start, end) => Array.from({length: end - start}, (v, k) => k + start)
const generateEmptyOption = label => ({value: '', label, disabled: true})
const validateFields = fields => {
  let valid = true
  Object.keys(fields).forEach(key => {
    const value = fields[key]
    const isArray = Array.isArray(value)
    if ((isArray && value.length === 0) || (!isArray && !value)) {
      valid = false
    }
  })

  return valid
}

const ALL_TECHNOLOGIES_SLUG = 'ALL'

const TenderRequest: React.FC<React.PropsWithChildren> = () => {
  const {translate} = useLocalization()
  const {alertStore} = useStore()
  const rootService = useAtomValue(rootServiceAtom)
  const party = useAtomValue(partyAtom)
  const lastItemRef = useRef<HTMLTableRowElement>(null)
  const theme = useTheme()
  const months = getMonths()
  const [volumes, setVolumes] = useState([])
  const totalVolume = volumes.reduce((acc, volume) => acc + volume.quantity, 0)
  const years = getPastYears(4)
  const {data: certData, loading} = useAtomValue(greenCertificatesAtom)
  const [dateFilter, setDateFilter] = useAtom(greenCertsDateFilterAtom)
  const technologyOptions = [
    SiteProductionTechnology.HYDRO,
    SiteProductionTechnology.WIND,
    SiteProductionTechnology.SOLAR,
  ]
  const allTechnologies: IChoice = {
    name: translate('All'),
    slug: ALL_TECHNOLOGIES_SLUG,
  }
  const [tenderData, setTenderData] = useState({
    technologies: [allTechnologies],
    countries: [],
    maxAgeYears: undefined,
    prodPeriodYears: undefined,
    expiresInMonths: undefined,
  })

  const technologies: IChoice[] = [
    allTechnologies,
    ...technologyOptions.map(technology => ({
      name: translate(capitalize(SiteProductionTechnology[technology])).toUpperCase(),
      slug: technology,
    })),
  ]

  const handleDateChange = (event, key) => {
    const value = getChangeEventValue(event)

    setDateFilter({...dateFilter, [key]: value})
  }

  const handleTenderDataChange = (e, key, isNumber = true) => {
    const value = getChangeEventValue(e)
    setTenderData({...tenderData, [key]: isNumber ? +value : value})
  }

  const handleSubmit = values => {
    setVolumes([...volumes, {id: uniqueId(), quantity: +values.quantity, month: +values.month, year: +values.year}])
    lastItemRef.current.scrollIntoView({behavior: 'smooth'})
  }

  const handleSend = async () => {
    const {countries, technologies, ...rest} = tenderData
    const formattedTechnologies = technologies.map(tech => tech.slug)
    const dataToValidate = {
      ...rest,
      technologies: formattedTechnologies.includes(ALL_TECHNOLOGIES_SLUG)
        ? technologyOptions
        : (formattedTechnologies as SiteProductionTechnology[]),
      monthlyCertificates: volumes.map(({id, ...rest}) => ({
        ...rest,
      })),
    }

    if (!validateFields(dataToValidate)) {
      alertStore.addError(translate('All fields must be filled'))
      return
    }

    try {
      await rootService.v2ContentService.sendTenderRequest(party.id, {
        ...dataToValidate,
        countries: countries?.map(country => country.value),
      })
      alertStore.addSuccess(translate('Successfully sent tender request'))
    } catch (e) {
      alertStore.addError(translate('Failed to send tender request'), e?.correlationId, e?.message)
    }
  }

  if (loading) return <FullscreenLoader />

  return (
    <Page
      title={translate('Tender request')}
      corner={
        <Box direction="row" align="center" gap={2}>
          <Box>
            <Button onClick={handleSend}>{translate('Send')}</Button>
          </Box>
        </Box>
      }
    >
      <InnerScroll>
        <Box direction="row" gap={2} margin={{top: 4}}>
          <Input
            onChange={e => handleDateChange(e, 'dayOfMonth')}
            label={translate('Day of month')}
            value={{day: dateFilter.dayOfMonth, month: dateFilter.month, year: dateFilter.year}}
            type="day"
          />
          <Select
            value={dateFilter.month}
            label={translate('Month')}
            options={months.map(month => ({value: month, label: month}))}
            onChange={e => handleDateChange(e, 'month')}
          />
          <Select
            value={dateFilter.year}
            label={translate('Year')}
            options={years.map(year => ({value: year, label: `${year}`}))}
            onChange={e => handleDateChange(e, 'year')}
          />
        </Box>
        <CertificatesChart certData={certData} filter={{showExcess: true, showMisisng: true}} />
        <Box margin={{top: 3}} direction="row" gap={5}>
          <Box>
            <Text size="xlarge" bold>
              {translate('Technology preferences')}
            </Text>
            <Filter
              choices={technologies}
              multiselect
              colored
              transparent
              skipDate
              technology
              name="technologies"
              setActiveChoice={choices => {
                setTenderData({...tenderData, technologies: choices as IChoice[]})
              }}
            />
          </Box>

          <Box width="408px">
            <Text size="xlarge" bold margin={{bottom: 2}}>
              {translate('Location preferences')}
            </Text>
            <MultiSelect
              value={tenderData.countries as any}
              placeholder={translate('Any AIB country')}
              data={[
                ...theme.countries.map(country => ({
                  value: country,
                  label: getHumanReadableCountry(country, translate),
                })),
              ]}
              onChange={e => handleTenderDataChange(e, 'countries', false)}
              parse={value => value}
            />
          </Box>
        </Box>

        <Box margin={{top: 3}} direction="row" gap={5}>
          <Box width="400px">
            <Text size="xlarge" bold margin={{bottom: 2}}>
              {translate('Generation plant max age')}
            </Text>
            <Select
              value={tenderData.maxAgeYears}
              defaultValue={''}
              type="number"
              options={[
                generateEmptyOption(translate('Select max year')),
                ...generateRange(1, 11).map(year => ({
                  value: year,
                  label: `${year} ${translate(year === 1 ? 'year' : 'years')}`,
                })),
              ]}
              onChange={e => handleTenderDataChange(e, 'maxAgeYears')}
            />
          </Box>
          <Box>
            <Text size="xlarge" bold margin={{bottom: 2}}>
              {translate('Delivery period')}
            </Text>
            <Select
              value={tenderData.prodPeriodYears}
              defaultValue={''}
              type="number"
              options={[
                generateEmptyOption(translate('Select delivery period')),
                ...generateRange(1, 6).map(year => ({
                  value: year,
                  label: `${year} ${translate(year === 1 ? 'year' : 'years')}`,
                })),
              ]}
              onChange={e => handleTenderDataChange(e, 'prodPeriodYears')}
            />
          </Box>
          <Box>
            <Text size="xlarge" bold margin={{bottom: 2}}>
              {translate('Expiration date')}
            </Text>
            <Select
              value={tenderData.expiresInMonths}
              defaultValue={''}
              type="number"
              options={[
                generateEmptyOption(translate('Select expiration date')),
                ...generateRange(2, 13).map(month => ({
                  value: month,
                  label: `${month} ${translate('months')}`,
                })),
              ]}
              onChange={e => handleTenderDataChange(e, 'expiresInMonths')}
            />
          </Box>
        </Box>

        <Box margin={{top: 3}}>
          <Box direction="row" justify="space-between">
            <Text size="xlarge" bold>
              {translate('Volume preferences')}
            </Text>
            <Text size="large" bold margin={{top: 2}}>
              {translate('Total certificate quantity')}: {totalVolume}
            </Text>
          </Box>

          <Form onSubmit={handleSubmit} submissionFeedback={false} resetOnSubmit defaultValues={{quantity: null}}>
            <StyledTable>
              <thead>
                <tr>
                  <StyledHeadCell>{translate('Year')}</StyledHeadCell>
                  <StyledHeadCell>{translate('Month')}</StyledHeadCell>
                  <StyledHeadCell>{translate('Certificate quantity')}</StyledHeadCell>
                  <StyledHeadCell>{translate('Actions')}</StyledHeadCell>
                </tr>
              </thead>
              <tbody>
                <StyledRow>
                  <td>
                    <FormSelect
                      required
                      name="year"
                      margin={0}
                      options={years.map(year => ({value: year, label: `${year}`}))}
                      placeholder="Year"
                    />
                  </td>
                  <td>
                    <FormSelect
                      required
                      name="month"
                      margin={0}
                      options={months.map((month, index) => ({value: index + 1, label: month}))}
                      placeholder="Month"
                    />
                  </td>
                  <td>
                    <FormInput
                      type="number"
                      defaultValue={null}
                      required
                      name="quantity"
                      margin={0}
                      placeholder="Certificates"
                    />
                  </td>
                  <td width="10%">
                    <IconButton type="submit">
                      <AddIcon />
                    </IconButton>
                  </td>
                </StyledRow>
                {volumes.map(volume => (
                  <StyledRow key={volume.id}>
                    <td>{volume.year}</td>
                    <td>{volume.month}</td>
                    <td>{volume.quantity}</td>
                    <td>
                      <IconButton
                        color="warning"
                        onClick={() => setVolumes(volumes.filter(item => item.id !== volume.id))}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </td>
                  </StyledRow>
                ))}
                <tr ref={lastItemRef} />
              </tbody>
            </StyledTable>
          </Form>
        </Box>
      </InnerScroll>
    </Page>
  )
}

export default TenderRequest
