import React from 'react'
import useLocalization from '../../../hooks/useLocalization'
import useStore from '../../../hooks/useStore'
import useServices from '../../../hooks/useServices'
import IParty from '../../../domain/IParty'
import Form from '../../Form'
import Box from '../../Box'
import Label from '../../Label'
import FormInput from '../../Form/FormInput'
import Text from '../../Text'
import FormSubmitButton from '../../Form/FormSubmitButton'
import useStoreData from '../../../hooks/useStoreData'
import LocationSearch from '../Portfolio/Site/LocationSearch'
import {isNil} from 'lodash-es'

const CompanyInformationForm: React.FC<React.PropsWithChildren> = () => {
  const {translate} = useLocalization()
  const {profileStore} = useStore()
  const {contentService} = useServices()
  const partyMember = useStoreData(store => store.profileStore.partyMembership)
  const {party} = partyMember || {}

  const handleSubmit = async (changedParty: IParty) => {
    const updatedPartyMember = await contentService.savePartyForCurrentUser({
      ...partyMember,
      party: {...party, ...changedParty},
    })
    profileStore.setPartyMember(updatedPartyMember)
  }

  return (
    <Form onSubmit={handleSubmit} defaultValues={party}>
      <Box margin={{bottom: 3}}>
        <Label text={translate('Account type')}>{translate('Corporate account')}</Label>
      </Box>

      <FormInput label={translate('Company name')} name="name" required />

      <FormInput label={translate('Company reg. number')} name="registrationCode" />

      <FormInput label={translate('Company vat. number')} name="vatCode" />

      {party?.meta?.walletId && <FormInput label={translate('Wallet Id')} readOnly name="meta.walletId" />}

      <Box margin={{bottom: 0.5}}>
        <Label text={translate('Address')} />
      </Box>
      <LocationSearch location={party?.location} required={isNil(party?.location)} variant="primary" />

      <FormSubmitButton margin={{top: 2}}>{translate('Save')}</FormSubmitButton>
    </Form>
  )
}

export default CompanyInformationForm
