import {rootServiceAtom} from 'atoms/general'
import {partyAtom} from 'atoms/party'
import Button from 'components/Button'
import {TableValue} from 'components/Table'
import ISubscription, {BillingMode, SubscriptionStatus} from 'domain/ISubscription'
import {humanReadablePlan, humanReadableSubscriptionStatus} from 'helpers/subscription'
import {useAtomValue} from 'jotai'
import React, {useState} from 'react'
import {useHistory} from 'react-router-dom'
import styled from 'styled-components'
import Grid from '../../../../components/Grid'
import {DateFormat, formatDate} from '../../../../helpers/date'
import useLocalization from '../../../../hooks/useLocalization'
import ConfirmationDialog from 'components/ConfirmationDialog'
import Text from 'components/Text'
import Box from 'components/Box'
import useTheme from 'hooks/useTheme'
import {formatMoney} from 'helpers/format'
import {CurrencyCode} from 'domain/IPrice'

interface IProps extends React.PropsWithChildren {
  subscriptions: ISubscription[]
  loading: boolean
}

const Card = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  margin-bottom: 16px;
`

export const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 80%;
`

const Value = styled.span``

export const Status = styled.span<{isActive: boolean}>`
  background-color: ${props => (props.isActive ? '#e6f4ea' : '#fce8e6')};
  color: ${props => (props.isActive ? '#34a853' : '#ea4335')};
  padding: 4px 8px;
  border-radius: 4px;
  text-align: center;
  height: fit-content;
`

const ValueItem = styled.div<{width?: string}>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  text-align: left;
  line-height: 48px;
  width: ${props => props.width || 'fit-content'};
`

export const ActionSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
`

const SubscriptionList: React.FC<IProps> = ({subscriptions}) => {
  const {translate} = useLocalization()
  const history = useHistory()
  const rootService = useAtomValue(rootServiceAtom)
  const {v2ContentService} = rootService
  const party = useAtomValue(partyAtom)
  const theme = useTheme()
  const [cancelModalOpen, setCancelModalOpen] = useState(false)
  const [subscriptionToCancel, setSubscriptionToCancel] = useState<ISubscription | null>(null)

  const handleCancel = async (subscription_id: number) => {
    await v2ContentService.cancelSubscription(party.id, subscription_id)
    setCancelModalOpen(false)
  }

  const handleViewDetails = (subscriptionId: number) => {
    return history.push(`/consumer/company/subscription/${subscriptionId}`)
  }

  if (subscriptions.length === 0) {
    return <Text size="large">{translate('No subscriptions found')}</Text>
  }

  return (
    <>
      <Grid columns={1} gap={1} data-cy="contract-list-items">
        {subscriptions?.map(subscription => (
          <Card key={subscription.id}>
            <Box direction="column" gap={2} width="100%">
              <Text size="large" bold>
                {humanReadablePlan(subscription.plan)}
              </Text>
              <Box>
                <Row>
                  <ValueItem>
                    <Text size="medium" font={theme.font.secondaryFont} color={theme.colors.accent}>
                      {translate('Amount')}
                    </Text>
                    <Text size="xlarge" bold font={theme.font.secondaryFont}>
                      {formatMoney(subscription.amount)} {CurrencyCode[subscription.currencyCode]}
                    </Text>
                    <Text size="small" uppercase font={theme.font.secondaryFont} color={theme.colors.accent}>
                      {subscription.billingMode === BillingMode.MONTHLY ? '/month' : '/year'}
                    </Text>
                  </ValueItem>
                  <ValueItem>
                    <Text size="medium" font={theme.font.secondaryFont} color={theme.colors.accent}>
                      {translate('Status')}
                    </Text>
                    <Box>
                      <Status isActive={subscription.status === SubscriptionStatus.Active}>
                        {humanReadableSubscriptionStatus(subscription.status)}
                      </Status>
                    </Box>
                  </ValueItem>
                  {subscription.status === SubscriptionStatus.Active && subscription.nextBillOn && (
                    <ValueItem>
                      <Text size="medium" font={theme.font.secondaryFont} color={theme.colors.accent}>
                        {translate('Renewal Date')}
                      </Text>
                      <Value>{formatDate(subscription.nextBillOn, DateFormat.MONTH_DAY_YEAR)}</Value>
                    </ValueItem>
                  )}
                  {subscription.status === SubscriptionStatus.Active &&
                    !subscription.nextBillOn &&
                    subscription.endsOn && (
                      <ValueItem width="130px">
                        <Text size="medium" font={theme.font.secondaryFont} color={theme.colors.accent}>
                          {translate('Ends at')}
                        </Text>
                        <Value>{formatDate(subscription.endsOn, DateFormat.MONTH_DAY_YEAR)}</Value>
                      </ValueItem>
                    )}
                  {subscription.status !== SubscriptionStatus.Active && (
                    <ValueItem width="130px">
                      <Text size="medium" font={theme.font.secondaryFont} color={theme.colors.accent}>
                        {translate('Ended at')}:
                      </Text>
                      <Value>{formatDate(subscription.endsOn, DateFormat.MONTH_DAY_YEAR)}</Value>
                    </ValueItem>
                  )}
                </Row>
              </Box>
              <Box direction="row" gap={2} align="end" style={{alignSelf: 'flex-end'}}>
                {subscription.status === SubscriptionStatus.Active && (
                  <Button
                    small
                    variant="secondary"
                    onClick={() => {
                      setSubscriptionToCancel(subscription)
                      setCancelModalOpen(true)
                    }}
                  >
                    {translate('Cancel subscription')}
                  </Button>
                )}
                <Button small onClick={() => handleViewDetails(subscription.id)}>
                  {translate('View Subscription')}
                </Button>
              </Box>
            </Box>
          </Card>
        ))}
      </Grid>
      <ConfirmationDialog
        open={cancelModalOpen}
        setOpen={setCancelModalOpen}
        title={translate('Cancel subscription')}
        acceptText={translate('Cancel subscription')}
        declineText={translate('Close')}
        onAccept={() => handleCancel(subscriptionToCancel?.id)}
        text={translate('Are you sure you want to cancel this subscription?')}
      />
    </>
  )
}

export default SubscriptionList
