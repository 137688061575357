import {atom, PrimitiveAtom} from 'jotai'
import {atomWithRefresh} from 'jotai/utils'
import {rootServiceAtom} from './general'
import {partyAtom} from './party'
import {loadable} from './utils'

const DEFAULT_PAGE_SIZE = 5
const DEFAULT_PAGE = 1

export const pageAtom = atom(DEFAULT_PAGE)
export const pageSizeAtom = atom(DEFAULT_PAGE_SIZE)

export const subscriptionIdAtom = atom<number>(null) as PrimitiveAtom<number>

export const subscriptionsAtom = loadable(
  atomWithRefresh(async get => {
    const services = get(rootServiceAtom)
    const party = get(partyAtom)
    const page = get(pageAtom)
    const pageSize = get(pageSizeAtom)

    return await services.v2ContentService.getSubscriptions(party.id, page, pageSize)
  }),
)

export const subscriptionAtom = loadable(
  atom(async get => {
    const services = get(rootServiceAtom)
    const party = get(partyAtom)
    const subscriptionId = get(subscriptionIdAtom)
    if (!subscriptionId) {
      return null
    }

    return await services.v2ContentService.getSubscription(party.id, subscriptionId)
  }),
  {nullIsLoading: true},
)
