import React, {useEffect} from 'react'
import useStore from 'hooks/useStore'
import InnerScroll from 'components/InnerScroll'
import Page from 'components/Page'
import useLocalization from 'hooks/useLocalization'
import useResource from 'hooks/useResource'
import Box from 'components/Box'
import Text from 'components/Text'
import ActionBox from 'components/Platform/ActionBox'
import {capitalize} from 'lodash-es'
import ProposalDetailsChart from 'components/Platform/Buy/CertificateDesigner/ProposalDetailsChart'
import ProposalDetailsInfo from 'components/Platform/Buy/CertificateDesigner/ProposalDetailsInfo'
import Button from 'components/Button'
import IOffer, {OfferState} from 'domain/IOffer'
import OfferProduct from './OfferDesignerCertificate/OfferProduct'
import OfferProposalDetails from './OfferDesignerCertificate/OfferProposalDetails'
import useTheme from 'hooks/useTheme'

interface IProps extends React.PropsWithChildren {
  offer: IOffer
}

const OfferDesignerCertificatePage: React.FC<IProps> = ({offer}) => {
  const {translate} = useLocalization()
  const theme = useTheme()
  const {alertStore, offerDesignerCertificateStore} = useStore()
  const [actionPerformed, setActionPerformed] = React.useState(false)
  const {certOffer, loading} = useResource(store => ({
    certOffer: store.offerDesignerCertificateStore.certOffer,
    loading: store.offerDesignerCertificateStore.loading,
  }))
  const {totalPrice, averagePrice, greenCoverage, timeseries, product, currency} = certOffer || {}
  const showActions = offer.state === OfferState.CONFIG_SENT_FOR_SELLER_REVIEW && !actionPerformed

  useEffect(() => {
    offerDesignerCertificateStore.loadCertificateOffer(offer.id)
  }, [])

  const handleApprove = async () => {
    try {
      await offerDesignerCertificateStore.approveRequest(offer.id)

      alertStore.addSuccess(translate('Successfully approved'))
      setActionPerformed(true)
    } catch (e) {
      alertStore.addError(translate('Failed to approve %s', ''), e?.correlationId, e?.message)
    }
  }

  const handleDecline = async () => {
    try {
      await offerDesignerCertificateStore.declineRequest(offer.id)

      alertStore.addSuccess(translate('Successfully declined'))
      setActionPerformed(true)
    } catch (e) {
      alertStore.addError(translate('Failed to approve %s', ''), e?.correlationId, e?.message)
    }
  }

  return (
    <Page
      title={translate('Certificate purchase request')}
      description={translate('Review purchase request and add discounts')}
      corner={
        <>
          {showActions && (
            <Box gap={2}>
              <Button onClick={handleDecline} variant="secondary">
                {translate('Decline')}
              </Button>
              <Button onClick={handleApprove} type="button">
                {translate('Approve')}
              </Button>
            </Box>
          )}
          {offer.state === OfferState.CONFIG_IN_PROGRESS && (
            <Box height={'100%'} align="center">
              <Text bold>
                {translate('Once this request is sent for review, you will be able to approve/decline it')}
              </Text>
            </Box>
          )}
          {offer.state === OfferState.CONTRACT_SENT && (
            <Box height={'100%'} align="center">
              <Text bold>
                {translate('Offer is currently in review by the buyer. You will be notified once they sign or decline')}
              </Text>
            </Box>
          )}
        </>
      }
      isLoading={loading}
    >
      <InnerScroll>
        <Box direction="column" gap={2}>
          <Box direction="row" gap={2}>
            <Box width={60} round border>
              <ActionBox title={translate('Offer summary')} fullWidth noBorder>
                <OfferProposalDetails offer={certOffer} />
              </ActionBox>
            </Box>

            <Box width="100%" round border>
              <ActionBox title={translate('Offer details')} fullWidth noBorder>
                <Box direction="column" gap={3}>
                  <ProposalDetailsInfo
                    hasConsumption
                    proposalDetails={{totalPrice, averagePrice, greenCoverage, timeseries, currency}}
                    draft
                  />
                  <ProposalDetailsChart technology={product?.technology} timeseries={timeseries} />
                </Box>
              </ActionBox>
            </Box>
          </Box>
          <Box>
            <OfferProduct key={product?.name} product={product} currency={currency} />
          </Box>
        </Box>
      </InnerScroll>
    </Page>
  )
}

export default OfferDesignerCertificatePage
