import React, {useState} from 'react'
import {SiteType} from '../../../../../domain/ISite'
import TextLink from '../../../../TextLink'
import useLocalization from '../../../../../hooks/useLocalization'
import {getCurrentYear, getPrevMonth} from 'helpers/date'
import Box from 'components/Box'
import {FormControlLabel, RadioGroup} from '@mui/material'
import Radio from 'components/Radio'
import {getChangeEventValue} from 'helpers/misc'
import env from '@beam-australia/react-env'

interface IProps extends React.PropsWithChildren {
  siteType?: SiteType
  decoration?: 'underline' | 'none'
  noColor?: boolean
  interval?: {
    month: string
    year: number
  }
}

enum HourlyType {
  HALF_HOURLY = 'half-hourly',
  QUARTER_HOURLY = 'quarter-hourly',
  HOURLY = 'hourly',
}

const DownloadExampleLink: React.FC<IProps> = ({interval, siteType, noColor, ...props}) => {
  const [hourlyType, setHourlyType] = useState(HourlyType.HALF_HOURLY)
  const {translate} = useLocalization()
  const month = interval?.month || getPrevMonth()
  const year = interval?.year || getCurrentYear()

  return (
    <Box direction="row" gap={4} align="center">
      <TextLink
        download
        to={`${env('CORE_V2_URL')}/v2/api/excel-templates/${hourlyType}/download?year=${year}&month=${month}`}
        noColor={noColor}
        {...props}
      >
        {translate('Download example file')}
      </TextLink>
      <RadioGroup row value={hourlyType} onChange={e => setHourlyType(getChangeEventValue(e))}>
        <FormControlLabel
          value={HourlyType.HALF_HOURLY}
          control={<Radio noColor={noColor} />}
          label={translate('Half-hourly')}
        />
        <FormControlLabel
          value={HourlyType.QUARTER_HOURLY}
          control={<Radio noColor={noColor} />}
          label={translate('Quarter-hourly')}
        />
        <FormControlLabel value={HourlyType.HOURLY} control={<Radio noColor={noColor} />} label={translate('Hourly')} />
      </RadioGroup>
    </Box>
  )
}

export default DownloadExampleLink
