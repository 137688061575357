import React, {useState} from 'react'
import {useHistory} from 'react-router-dom'
import useLocalization from '../../../../hooks/useLocalization'
import Page from '../../../Page'
import useStore from '../../../../hooks/useStore'
import FormSubmitButton from '../../../Form/FormSubmitButton'
import Form from '../../../Form'
import FormInput from '../../../Form/FormInput'
import Heading from '../../../Heading'
import Grid from '../../../Grid'
import Box from '../../../Box'
import IParty, {PartyRole, PartyState} from '../../../../domain/IParty'
import InnerScroll from '../../../InnerScroll'
import {ResourceId} from '../../../../types'
import {getPartySite} from '../../../../helpers/party'
import {getSiteId} from '../../../../helpers/site'
import FormRepresentative from '../../Form/FormRepresentative'
import LocationSearch from '../Site/LocationSearch'
import {isNil} from 'lodash-es'
import {ViewType} from './CustomerEditPage'
import {IMeta} from 'hooks/useFormContext'

interface IProps extends React.PropsWithChildren {
  party?: IParty
  viewType?: ViewType
}

enum ButtonId {
  Save,
  SaveAndCreateOffer,
}

const CompanyInfo: React.FC<IProps> = ({party, viewType}) => {
  const [shouldUpdateParameterGroup, setShouldUpdateParameterGroup] = useState(false)
  const [pricingParameterGroupId, setPricingParameterGroupId] = useState(
    party?.meta?.pricingParameterGroupId || getPartySite(party)?.meta?.pricingParameterGroupId,
  )
  const {translate} = useLocalization()
  const {partyStore, alertStore} = useStore()
  const history = useHistory()

  const handleSubmit = async (
    {consumptionFile, newSiteId, ...partyData}: IParty & {consumptionFile: File; newSiteId: ResourceId},
    {meta}: {meta: IMeta},
  ) => {
    const siteId = getSiteId(newSiteId)
    const savedParty = await partyStore.saveParty(
      {
        ...party,
        ...partyData,
        totalAvgYearlyConsumptionMwh: consumptionFile ? null : partyData.totalAvgYearlyConsumptionMwh,
        meta: {
          ...party?.meta,
          ...partyData?.meta,
          pricingParameterGroupId,
        },
        state: PartyState.ACTIVE,
      },
      siteId,
    )

    alertStore.addSuccess(translate('Successfully saved %s', savedParty.name))

    try {
      await partyStore.saveConsumption(savedParty.id, consumptionFile, siteId)

      if (meta.submitButtonId === ButtonId.SaveAndCreateOffer) {
        history.push(`/broker/sell/offers/add?customer.id=${savedParty.id}`)
      } else {
        history.push(`/broker/portfolio/customers`)
      }
    } catch (e) {
      alertStore.addError(translate('Failed to upload %s', 'consumption profile'), e?.correlationId, e?.message)
      history.push(`/broker/portfolio/customers/${savedParty.id}`)
    }
  }

  return (
    <>
      <Form onSubmit={handleSubmit} fillHeight defaultValues={party} submissionFeedback={null}>
        <Box round border>
          <Page
            title={translate('Customer profile')}
            description={translate('Manage company information and consumption profile')}
            corner={
              <Box gap={2} align="center">
                <FormSubmitButton buttonId={ButtonId.Save}>{translate('Save')}</FormSubmitButton>
              </Box>
            }
          >
            <InnerScroll>
              <Grid columns={2} gap={5}>
                <div>
                  <FormInput name="partyRole" type="hidden" defaultValue={PartyRole.BUYER} />
                  <FormInput label={translate('Company name')} name="name" required />

                  <FormInput label={translate('Company reg. number')} name="registrationCode" />

                  <FormInput label={translate('Company vat. number')} name="vatCode" />

                  <Heading size="small" margin={{bottom: 2}}>
                    {translate('Representative')}
                  </Heading>
                  <FormRepresentative viewType={viewType} />

                  <Heading size="small" margin={{bottom: 2}}>
                    {translate('Address')}
                  </Heading>
                  <LocationSearch location={party?.location} required={isNil(party?.location)} variant="primary" />
                </div>
              </Grid>
            </InnerScroll>
          </Page>
        </Box>
      </Form>
    </>
  )
}

export default CompanyInfo
