import React from 'react'
import Heading from '../../../Heading'
import useLocalization from '../../../../hooks/useLocalization'
import Box from '../../../Box'
import Text from '../../../Text'
import styled from 'styled-components'
import useTheme from '../../../../hooks/useTheme'
import {ModalType} from 'components/Modal/IModal'
import ModalLink from 'components/Modal/ModalLink'
import ITheme from 'theme/ITheme'

const Container = styled(Box)`
  max-width: 650px;
  max-height: none;
  flex-direction: column;
  display: flex;
  color: ${props => props.theme.colors.primary};
`

const StyledGreenLogo = styled.img`
  width: 128px;
  margin-bottom: 30px;
`

const StyledLogo = styled.img`
  height: 12px;
  filter: invert(1);
  opacity: 0.5;
  margin-bottom: 4px;
`

export const FooterLink = styled(ModalLink)`
  display: block;
  color: ${props => (props.theme as ITheme).colors.primaryDark};
  font-size: 12px;
  &:hover {
    text-decoration: underline;
  }
`

const TitleWithText: React.FC<{title: string; text?: string}> = ({title, text}) => {
  const theme = useTheme()
  return (
    <Box gap={0.5} direction="column">
      <Heading size="medium" lineHeight="msmall" font={theme.font.headings} transform="capitalize">
        {title}
      </Heading>
      {text && (
        <Text size="large" lineHeight="msmall" color={theme.colors.surface} style={{fontFamily: 'OpenSans'}}>
          {text}
        </Text>
      )}
    </Box>
  )
}

const Aside = () => {
  const {translate} = useLocalization()
  const theme = useTheme()
  const heading1 = translate('%s', theme.loginText.heading1)
  const heading2 = translate('%s', theme.loginText.heading2)
  const heading3 = translate('%s', theme.loginText.heading3)
  const iconTitle1 = translate('%s', theme.loginText.iconTitle1)
  const iconTitle2 = translate('%s', theme.loginText.iconTitle2)
  const iconTitle3 = translate('%s', theme.loginText.iconTitle3)
  const iconTitle4 = translate('%s', theme.loginText.iconTitle4)
  const iconText1 = translate('%s', theme.loginText.iconText1)
  const iconText2 = translate('%s', theme.loginText.iconText2)
  const iconText3 = translate('%s', theme.loginText.iconText3)
  const iconText4 = translate('%s', theme.loginText.iconText4)

  return (
    <>
      <Container style={{flex: 1}}>
        <StyledGreenLogo src={theme.logo.greenLogo} alt="green-logo" />
        <Heading size="slarge" font={theme.font.headings} pad={{top: 3}}>
          {heading1}
        </Heading>

        {/* <Box justify="space-between">
          <TitleWithText title={heading2} text={heading3} />
        </Box>
        <Box justify="space-between">
          <TitleWithText title={iconTitle1} text={iconText1} />
        </Box>
        <Box justify="space-between">
          <TitleWithText title={iconTitle2} text={iconText2} />
        </Box>
        <Box justify="space-between">
          <TitleWithText title={iconTitle3} text={iconText3} />
        </Box>
        <Box justify="space-between">
          <TitleWithText title={iconTitle4} text={iconText4} />
        </Box> */}
      </Container>
      <Container width="100%" margin={{top: 2}}>
        <Box justify="space-between" direction="column" align="start" gap={0.5} pad={{bottom: 3}} data-c="Footer">
          <StyledLogo src={theme.logo.logo} alt="logo" />
          <FooterLink modal={ModalType.TERMS}>{translate('Terms and conditions')}</FooterLink>
          <FooterLink modal={ModalType.COOKIES}>{translate('Cookie policy')}</FooterLink>
          <FooterLink modal={ModalType.PRIVACY}>{translate('Privacy policy')}</FooterLink>
          <FooterLink modal={ModalType.SECURITY}>{translate('Security')}</FooterLink>
          <FooterLink as="a" href="mailto:support@renewabl.com" data-cy="footer-support-link">
            {translate('Support')}
          </FooterLink>
        </Box>
      </Container>
    </>
  )
}

export default Aside
