import {Plan, SubscriptionStatus} from 'domain/ISubscription'

export const humanReadablePlan = (kind: Plan): string => {
  switch (kind) {
    case Plan.PRO:
      return 'Pro'
    case Plan.ENTERPRISE:
      return 'Enterprise'
  }
}

export const humanReadableSubscriptionStatus = (status: SubscriptionStatus): string => {
  switch (status) {
    case SubscriptionStatus.Active:
      return 'Active'
    case SubscriptionStatus.Canceled:
      return 'Canceled'
    default:
      return 'Ended'
  }
}
